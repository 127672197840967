@use "variables";
@use '../../node_modules/include-media/dist/include-media';

label {
    color: variables.$accentColor;
    font: 700 16px "Red Hat Display";
    @include include-media.media(">=tablet") {
        font-size: 12px;
    }
}
.InputFull, .InputHalf, .InputFourth, .InputEighth, .TextArea {
    border: 1px solid variables.$baseColor;
    background-color: white;
    color: variables.$baseColor;
}
.InputFull {
    width: 90%;
}
.InputHalf {
    width: 45%;
}
.InputFourth {
    width: 22%;
}
.InputEighth {
    width: 11%;
}
.InputContainer {
    @include variables.flexColumn(center);
    width: 100%;
    align-items: flex-start;
}
.TextArea {
    width: 100%;
    height: 100px;
}