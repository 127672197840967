@use "variables";
@use '../../node_modules/include-media/dist/include-media';

.MobileBottom {
    @include variables.flexColumn(space-between);
    width: 100vw;
    @include include-media.media(">=tablet") {
        width: 70%;
        margin: 70px auto 0;
    }
    .EmailUpdatesMobile {
        width: 100%;
        padding: 20px 0;
        background-color: variables.$baseColor;
        form {
            @include variables.flexColumn(space-evenly);
            border: 1px solid white;
            width: 90%;
            margin: auto;
            input {
                width: 70%;
                margin-top: 10px;
                &:first-child {
                    margin-top: 30px;
                }
                &::placeholder {
                    padding-left: 10px;
                }
            }
            .label {
                text-align: left;
                margin-left: 0;
                color: variables.$accentColor;
                font: 400 14px "Red Hat Display";
            }
        }
    }
    .SubmitButton {
        margin: 20px auto;
    }
    .Footer {
        @include variables.flexColumn(space-evenly);
        width: 100%;
        padding-top: 20px;
        background-color: variables.$baseColor;
        border-bottom: 10px solid variables.$accentColor;
        .FooterNav {
            @include variables.flexRow(space-around);
            gap: 25px;
            h4 {
                font: 400 24px "Kanit";
                color: white;
                border-bottom: 1px solid transparent;
                &:hover {
                    border-bottom: 1px solid white;
                }
            }
        }
        .DonateButton {
            text-align: center;
            margin: 15px auto;
        }
    }
}