@use 'variables';
@use '../../node_modules/include-media/dist/include-media';

.Contact {
    @include variables.centerContent;
    flex-direction: column;
    margin-bottom: 80px;
    .ContactInfo {
        @include variables.paragraph;
        width: 90%;
        margin: 20px auto;
        text-align: center;
        a {
            color: #ECA72C;
        }
        @include include-media.media(">=tablet") {
            width: 70%;
        }
    }
    .SocIcon {
        width: 100px;
        margin: auto;
        filter: brightness(0) saturate(100%) invert(10%) sepia(40%) saturate(5373%) hue-rotate(309deg) brightness(88%) contrast(96%);
        transition: filter 0.3s;
        &:hover {
            filter: brightness(0) saturate(100%) invert(81%) sepia(13%) saturate(4567%) hue-rotate(340deg) brightness(99%) contrast(87%);
        }
    }
}