@use 'variables';
@use '../../node_modules/include-media/dist/include-media';

.Success {
    h2, p {
        color: variables.$baseColor;
        text-align: center;
    }
    h2 {
        font: 700 48px "Kanit";
        margin-top: 2rem;
    }
    p {
        font: 400 20px "Red Hat Display";
    }
}