@use "variables";
@use '../../node_modules/include-media/dist/include-media';
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&family=Red+Hat+Display:wght@400;700&display=swap');

.App .Navbar {
    @include variables.flexRow(space-between);
    width: 100%;
    overflow-y: hidden;
    padding-top: 5px;
    height: 70px;
    background-color: variables.$baseColor;
    border-bottom: 7px solid variables.$accentColor;
    @include include-media.media(">=desktop") {
        @include variables.flexColumn(flex-start);
        box-sizing: border-box;
        align-items: center;
        height: 100vh;
        width: 20vw;
        position: fixed;
        z-index: 1;
        top: 0;
        padding: 0;
        border-right: 7px solid variables.$accentColor;
        border-bottom: none;
    }
    .Logo {
        width: 45%;
        height: auto;
        @include include-media.media(">=tablet", "<=desktop") {
            margin-left: 7%;
            height: 100%;
            width: auto;
        }
        @include include-media.media(">=desktop") {
            margin-top: 5%;
            width: 90%;
        }
    }
    .toggleButton {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
        background-color: transparent;
        border: none;
        margin-right: 5%;
        .menuToggleButton {
            width: 100%;
            height: 100%;
            filter: invert(100%);
        }
    }
    .NavContainer {
        display: none;
        @include include-media.media(">=desktop") {
            @include variables.flexColumn(space-evenly);
            align-items: center;
            gap: 10px;
            margin-top: 10px;
            height: 90%;
            padding-bottom: 5vh;
            p {
                color: white;
                text-align: center;
                width: 90%;
            }
            h3 {
                width: 90%;
                color: white;
                font: 700 28px "Kanit";
                text-align: center;
                margin-bottom: -10px;
                border: 3px solid variables.$accentColor;
                transition: border 0.5s;
                @include include-media.media(">=large") {
                    font-size: 36px;
                }
                &:hover {
                    border-color: white;
                }
                &:hover a {
                    color: white;
                }
            }
            a {
                text-decoration: none;
                color: variables.$accentColor;
                transition: color 0.5s, border-color 0.5s;
                &:hover {
                    color: white;
                }
            }
            .NavButtonContainer {
                @include variables.flexColumn(space-evenly);
                align-items: center;
                gap: 0.5vh;
                .NavButton {
                    font: 400 40px "Kanit";
                    color: white;
                    margin-top: 0;
                    border-bottom: 1px solid transparent;
                    transition: border 0.5s, color 0.5s;
                    @include include-media.media(">=large") {
                        font-size: 55px;
                    }
                    // &:first-child {
                    //     &::before {
                    //         content: ' ';
                    //         display: inline-block;
                    //         background-image: url(../assets/img/homeIcon.svg);
                    //         background-size: 34px;
                    //         width: 34px;
                    //         height: 34px;
                    //         margin-right: 1vw;
                    //         background-repeat: no-repeat;
                    //     }
                    // }
                    // &:nth-child(2) {
                    //     &::before {
                    //         content: ' ';
                    //         display: inline-block;
                    //         background-image: url(../assets/img/aboutIcon.svg);
                    //         background-size: 34px;
                    //         width: 34px;
                    //         height: 34px;
                    //         margin-right: 1vw;
                    //         background-repeat: no-repeat;
                    //     }
                    // }
                    // &:nth-child(3) {
                    //     &::before {
                    //         content: ' ';
                    //         display: inline-block;
                    //         background-image: url(../assets/img/applyIcon.svg);
                    //         background-size: 34px;
                    //         width: 34px;
                    //         height: 34px;
                    //         margin-right: 1vw;
                    //         background-repeat: no-repeat;
                    //     }
                    // }
                    // &:nth-child(4) {
                    //     &::before {
                    //         content: ' ';
                    //         display: inline-block;
                    //         background-image: url(../assets/img/contactIcon.svg);
                    //         background-size: 34px;
                    //         width: 34px;
                    //         height: 34px;
                    //         margin-right: 1vw;
                    //         background-repeat: no-repeat;
                    //     }
                    // }
                    &:hover {
                        border-color: white;
                    }
                }
            }
            .NavDonateButton {
                // margin-top: 20px;
                width: 50%;
                height: 8%;
                font: 700 24px "Red Hat Display";
                background-color: transparent;
                border: 4px solid variables.$accentColor;
                border-radius: 10px;
                color: variables.$accentColor;
                align-self: center;
                transition: color 0.5s, background-color 0.5s;
                &:hover {
                    background-color: variables.$accentColor;
                    color: variables.$baseColor;
                    border-color: variables.$accentColor;
                }
                @include include-media.media(">=desktop") {
                    min-width: 120px;
                }
            }
        }
        #mc_embed_signup {
            @include variables.centerContent;
            flex-direction: column;
            width: 17vw;
            margin: 10px auto;
            border: 1px solid white;
            padding-bottom: 1vh;
            padding-left: 0;
            p {
                color: variables.$accentColor;
                font: 400 14px "Red Hat Display"; 
            }
            input {
                width: 15vw;
                margin: auto;
                padding: 0;
                &::placeholder {
                    padding-left: 5px;
                }
            }
        }
    }
    .MobileMenu {
        @include variables.flexColumn(space-evenly);
        position: absolute;
        top: 69px;
        z-index: 2;
        .LinkDiv {
            @include variables.centerContent;
            background-color: white;
            width: 100vw;
            height: 55px;
            margin: auto;
            text-align: center;
            border-bottom: 1px solid gray;
            transition: background-color 0.5s;
            &:hover {
                background-color: variables.$baseColor;
            }
            &:hover h3{
                color: white;
            }
        }
        a {
            text-decoration: none;
            color: variables.$baseColor;
            font: 400 16px "Kanit";
        }
        .ButtonContainer {
            @include variables.centerContent;
            width: 100vw;
            height: 50px;
            background-color: white;
            transition: background-color 0.5s;
            a {
                color: variables.$accentColor;
                text-align: center;
            }
            p {
                text-align: center;
            }
            &:hover {
                background-color: variables.$accentColor;
            }
            &:hover button {
                color: white;
            }
            button {
                background-color: transparent;
                border: none;
                font: 400 28px "Kanit";
                color: variables.$accentColor;
                transition: color 0.5s;
            }
        }
    }
    .untoggled {
        display: none;
    }
}