@use '../../node_modules/include-media/dist/include-media';

// Colors
$baseColor: #64113F;
$accentColor: #ECA72C;
$shadow: #8c8c8c;

// Fonts
$mobileP: 400 14px "Red Hat Display";

// Mixins
@mixin flexRow($jContentR) {
    display: flex;
    flex-direction: row;
    justify-content: $jContentR;
    align-items: center;
}

@mixin flexColumn($jContentC) {
    display: flex;
    flex-direction: column;
    justify-content: $jContentC;
    align-items: center;
}

@mixin centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin paragraph {
    font: 400 16px "Red Hat Display";
    color: $baseColor;
    text-align: center;
    @include include-media.media(">=tablet") {
        font-size: 20px;
    }
}
