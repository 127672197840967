@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&family=Red+Hat+Display:wght@400;700&display=swap");
.SubmitButton {
  background-color: transparent;
  color: white;
  font: 700 20px "Kanit";
  width: 30%;
  border-radius: 5px;
  border: 3px solid white;
  transition: background-color 0.5s, color 0.5s;
}
.SubmitButton:hover {
  background-color: white;
  color: #64113F;
}
@media (min-width: 600px) {
  .SubmitButton {
    max-width: 100px;
  }
}

.DonateButton {
  background-color: transparent;
  color: #ECA72C;
  font: 700 20px "Kanit";
  width: 30%;
  border-radius: 5px;
  border: 3px solid #ECA72C;
  transition: background-color 0.5s, color 0.5s;
}
.DonateButton:hover {
  background-color: #ECA72C;
  color: white;
}

#mc_embed_signup .clear {
  display: flex;
  justify-content: center;
  align-items: center;
}
#mc_embed_signup .clear .MCButton {
  background-color: transparent;
  color: #64113F;
  font: 700 20px "Kanit";
  margin: auto;
  width: 35%;
  padding: auto;
  border-radius: 5px;
  border: 3px solid #64113F;
  transition: background-color 0.5s, color 0.5s;
}
#mc_embed_signup .clear .MCButton:hover {
  background-color: #64113F;
  color: white;
}
@media (min-width: 600px) {
  #mc_embed_signup .clear .MCButton {
    max-width: 100px;
  }
}
@media (max-width: 600px) {
  #mc_embed_signup .clear .MCButton {
    color: white;
    border-color: white;
  }
  #mc_embed_signup .clear .MCButton:hover {
    color: #64113F;
    background-color: white;
  }
}
@media (min-width: 1024px) {
  #mc_embed_signup .clear .MCButton {
    width: 50%;
    min-width: 120px;
    max-width: none;
  }
}

.App .Navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  padding-top: 5px;
  height: 70px;
  background-color: #64113F;
  border-bottom: 7px solid #ECA72C;
}
@media (min-width: 1024px) {
  .App .Navbar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    align-items: center;
    height: 100vh;
    width: 20vw;
    position: fixed;
    z-index: 1;
    top: 0;
    padding: 0;
    border-right: 7px solid #ECA72C;
    border-bottom: none;
  }
}
.App .Navbar .Logo {
  width: 45%;
  height: auto;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .App .Navbar .Logo {
    margin-left: 7%;
    height: 100%;
    width: auto;
  }
}
@media (min-width: 1024px) {
  .App .Navbar .Logo {
    margin-top: 5%;
    width: 90%;
  }
}
.App .Navbar .toggleButton {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  background-color: transparent;
  border: none;
  margin-right: 5%;
}
.App .Navbar .toggleButton .menuToggleButton {
  width: 100%;
  height: 100%;
  filter: invert(100%);
}
.App .Navbar .NavContainer {
  display: none;
}
@media (min-width: 1024px) {
  .App .Navbar .NavContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    height: 90%;
    padding-bottom: 5vh;
  }
  .App .Navbar .NavContainer p {
    color: white;
    text-align: center;
    width: 90%;
  }
  .App .Navbar .NavContainer h3 {
    width: 90%;
    color: white;
    font: 700 28px "Kanit";
    text-align: center;
    margin-bottom: -10px;
    border: 3px solid #ECA72C;
    transition: border 0.5s;
  }
}
@media (min-width: 1024px) and (min-width: 1400px) {
  .App .Navbar .NavContainer h3 {
    font-size: 36px;
  }
}
@media (min-width: 1024px) {
  .App .Navbar .NavContainer h3:hover {
    border-color: white;
  }
}
@media (min-width: 1024px) {
  .App .Navbar .NavContainer h3:hover a {
    color: white;
  }
}
@media (min-width: 1024px) {
  .App .Navbar .NavContainer a {
    text-decoration: none;
    color: #ECA72C;
    transition: color 0.5s, border-color 0.5s;
  }
  .App .Navbar .NavContainer a:hover {
    color: white;
  }
}
@media (min-width: 1024px) {
  .App .Navbar .NavContainer .NavButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-items: center;
    gap: 0.5vh;
  }
  .App .Navbar .NavContainer .NavButtonContainer .NavButton {
    font: 400 40px "Kanit";
    color: white;
    margin-top: 0;
    border-bottom: 1px solid transparent;
    transition: border 0.5s, color 0.5s;
  }
}
@media (min-width: 1024px) and (min-width: 1400px) {
  .App .Navbar .NavContainer .NavButtonContainer .NavButton {
    font-size: 55px;
  }
}
@media (min-width: 1024px) {
  .App .Navbar .NavContainer .NavButtonContainer .NavButton:hover {
    border-color: white;
  }
}
@media (min-width: 1024px) {
  .App .Navbar .NavContainer .NavDonateButton {
    width: 50%;
    height: 8%;
    font: 700 24px "Red Hat Display";
    background-color: transparent;
    border: 4px solid #ECA72C;
    border-radius: 10px;
    color: #ECA72C;
    align-self: center;
    transition: color 0.5s, background-color 0.5s;
  }
  .App .Navbar .NavContainer .NavDonateButton:hover {
    background-color: #ECA72C;
    color: #64113F;
    border-color: #ECA72C;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .App .Navbar .NavContainer .NavDonateButton {
    min-width: 120px;
  }
}
.App .Navbar .NavContainer #mc_embed_signup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 17vw;
  margin: 10px auto;
  border: 1px solid white;
  padding-bottom: 1vh;
  padding-left: 0;
}
.App .Navbar .NavContainer #mc_embed_signup p {
  color: #ECA72C;
  font: 400 14px "Red Hat Display";
}
.App .Navbar .NavContainer #mc_embed_signup input {
  width: 15vw;
  margin: auto;
  padding: 0;
}
.App .Navbar .NavContainer #mc_embed_signup input::placeholder {
  padding-left: 5px;
}
.App .Navbar .MobileMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 69px;
  z-index: 2;
}
.App .Navbar .MobileMenu .LinkDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100vw;
  height: 55px;
  margin: auto;
  text-align: center;
  border-bottom: 1px solid gray;
  transition: background-color 0.5s;
}
.App .Navbar .MobileMenu .LinkDiv:hover {
  background-color: #64113F;
}
.App .Navbar .MobileMenu .LinkDiv:hover h3 {
  color: white;
}
.App .Navbar .MobileMenu a {
  text-decoration: none;
  color: #64113F;
  font: 400 16px "Kanit";
}
.App .Navbar .MobileMenu .ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 50px;
  background-color: white;
  transition: background-color 0.5s;
}
.App .Navbar .MobileMenu .ButtonContainer a {
  color: #ECA72C;
  text-align: center;
}
.App .Navbar .MobileMenu .ButtonContainer p {
  text-align: center;
}
.App .Navbar .MobileMenu .ButtonContainer:hover {
  background-color: #ECA72C;
}
.App .Navbar .MobileMenu .ButtonContainer:hover button {
  color: white;
}
.App .Navbar .MobileMenu .ButtonContainer button {
  background-color: transparent;
  border: none;
  font: 400 28px "Kanit";
  color: #ECA72C;
  transition: color 0.5s;
}
.App .Navbar .untoggled {
  display: none;
}

.MobileBottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}
@media (min-width: 600px) {
  .MobileBottom {
    width: 70%;
    margin: 70px auto 0;
  }
}
.MobileBottom .EmailUpdatesMobile {
  width: 100%;
  padding: 20px 0;
  background-color: #64113F;
}
.MobileBottom .EmailUpdatesMobile form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid white;
  width: 90%;
  margin: auto;
}
.MobileBottom .EmailUpdatesMobile form input {
  width: 70%;
  margin-top: 10px;
}
.MobileBottom .EmailUpdatesMobile form input:first-child {
  margin-top: 30px;
}
.MobileBottom .EmailUpdatesMobile form input::placeholder {
  padding-left: 10px;
}
.MobileBottom .EmailUpdatesMobile form .label {
  text-align: left;
  margin-left: 0;
  color: #ECA72C;
  font: 400 14px "Red Hat Display";
}
.MobileBottom .SubmitButton {
  margin: 20px auto;
}
.MobileBottom .Footer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  background-color: #64113F;
  border-bottom: 10px solid #ECA72C;
}
.MobileBottom .Footer .FooterNav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 25px;
}
.MobileBottom .Footer .FooterNav h4 {
  font: 400 24px "Kanit";
  color: white;
  border-bottom: 1px solid transparent;
}
.MobileBottom .Footer .FooterNav h4:hover {
  border-bottom: 1px solid white;
}
.MobileBottom .Footer .DonateButton {
  text-align: center;
  margin: 15px auto;
}

.Home {
  width: 100%;
  padding: 0;
  height: auto;
  overflow-x: hidden;
}
.Home .TopImage {
  object-position: 0 25%;
}
.Home .TopCard {
  background-image: url(../assets/img/SCLogo.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
@media (min-width: 600px) {
  .Home .TopCard {
    background-size: 70%;
  }
}
.Home .paragraph {
  font: 400 16px "Red Hat Display";
  color: #64113F;
  text-align: center;
  width: 80%;
  margin: 5% auto 0;
}
@media (min-width: 600px) {
  .Home .paragraph {
    font-size: 20px;
  }
}
.Home .SectionImage {
  object-fit: cover;
  object-position: 0 25%;
  margin: 15% auto;
}
@media (min-width: 600px) {
  .Home .SectionImage {
    height: 50vh;
    width: 80%;
    margin: 10% auto 2% 8vw;
  }
}
@media (min-width: 1024px) {
  .Home .CardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding-bottom: 0;
    margin-bottom: 0;
    margin: 0 0 0 -1%;
  }
}
.Home .AlumniCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  width: 90%;
  height: 90vh;
  margin: 5vh auto;
  padding: 0;
  box-shadow: 0 10px 10px #8c8c8c;
  border-radius: 10px;
}
@media (min-width: 600px) {
  .Home .AlumniCard {
    width: 60vw;
    height: auto;
  }
}
@media (min-width: 1024px) {
  .Home .AlumniCard {
    width: 25vw;
    min-width: 250px;
    height: auto;
  }
}
.Home .AlumniCard .AlumniImage {
  margin: 0;
  padding: 0;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  background-color: #64113F;
}
.Home .AlumniCard .AlumniName {
  display: block;
  width: 100%;
  text-align: center;
  font: 400 28px "Kanit";
  color: #64113F;
  border-bottom: 1px solid #64113F;
}
.Home .AlumniCard .QuoteContainer {
  text-align: center;
  height: 40%;
}
@media (min-width: 600px) {
  .Home .AlumniCard .QuoteContainer {
    height: 30%;
  }
}
@media (min-width: 1024px) {
  .Home .AlumniCard .QuoteContainer {
    height: 50%;
  }
}
.Home .AlumniCard .QuoteContainer .AlumniQuote {
  font: 400 14px "Red Hat Display";
  color: #64113F;
  width: 90%;
  margin: 1% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 600px) {
  .Home .AlumniCard .QuoteContainer .AlumniQuote {
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  .Home .AlumniCard .QuoteContainer .AlumniQuote {
    font-size: 12px;
  }
}
@media (min-width: 1400px) {
  .Home .AlumniCard .QuoteContainer .AlumniQuote {
    font-size: 16px;
  }
}
.Home .AlumniCard .AlumniYear {
  display: block;
  position: relative;
  bottom: 0;
  width: 100%;
  height: auto;
  text-align: center;
  margin: 2% auto 0;
  background-color: #64113F;
  color: #ECA72C;
  font: 700 40px "Kanit";
  border-radius: 0 0 10px 10px;
}
@media (min-width: 600px) {
  .Home .AlumniCard .AlumniYear {
    font-size: 50px;
  }
}
@media (min-width: 1024px) {
  .Home .AlumniCard .AlumniYear {
    font-size: 35px;
  }
}

.About .TopArea {
  margin-bottom: 10%;
}
.About .paragraph {
  font: 400 16px "Red Hat Display";
  color: #64113F;
  text-align: center;
  width: 80%;
  margin: auto auto 10%;
}
@media (min-width: 600px) {
  .About .paragraph {
    font-size: 20px;
  }
}
@media (min-width: 1025px) {
  .About .paragraph {
    width: 70%;
  }
}
.About .BoardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  margin: 10% auto;
}
@media (min-width: 1024px) {
  .About .BoardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
.About .BoardContainer .BoardCard {
  width: 80%;
  margin: auto;
  box-shadow: 0 5px 5px gray;
  border-radius: 10px;
}
@media (min-width: 600px) {
  .About .BoardContainer .BoardCard {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .About .BoardContainer .BoardCard {
    width: 80%;
  }
}
.About .BoardContainer .BoardCard .BoardImage {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}
.About .BoardContainer .BoardCard .BoardInfo {
  background-color: #64113F;
  color: white;
  font: 400 18px "Kanit";
  text-align: center;
  min-height: 160px;
  border-radius: 0 0 10px 10px;
}
.About .BoardContainer .BoardCard .BoardInfo .BoardName {
  border-bottom: 1px solid white;
  display: inline-block;
  width: 90%;
  margin-top: 2%;
}
.About .BoardContainer .BoardCard .BoardInfo .BoardTitle {
  max-width: 90%;
  margin: auto;
}
.About h4 {
  text-align: center;
  margin: -30px auto 15%;
  font: 700 24px "Red Hat Display";
  color: #64113F;
}

.Apply {
  white-space: pre-wrap;
  margin-bottom: 80px;
}
.Apply .ScholarshipInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 85%;
  margin: auto;
}
@media (min-width: 600px) {
  .Apply .ScholarshipInfo {
    width: 70%;
  }
}
@media (min-width: 1024px) {
  .Apply .ScholarshipInfo {
    width: 80%;
  }
}
.Apply .ScholarshipInfo .paragraph {
  font: 400 16px "Red Hat Display";
  color: #64113F;
  text-align: center;
  text-align: left;
}
@media (min-width: 600px) {
  .Apply .ScholarshipInfo .paragraph {
    font-size: 20px;
  }
}
.Apply .ScholarshipInfo .paragraph:first-child {
  margin-top: 15%;
}
.Apply .ScholarshipInfo .paragraph a {
  color: #ECA72C;
}
.Apply .ApplicationForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: auto;
}
@media (min-width: 600px) {
  .Apply .ApplicationForm {
    width: 70%;
  }
}
@media (min-width: 1024px) {
  .Apply .ApplicationForm {
    width: 80%;
  }
}
.Apply .ApplicationForm .SubmitButton {
  margin: auto;
  text-align: center;
  color: #64113F;
  border-color: #64113F;
}
.Apply .ApplicationForm .SubmitButton:hover {
  background-color: #64113F;
  color: white;
}
.Apply .ApplicationForm form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-items: flex-start;
  gap: 7px;
  padding: 10px 15px;
  border: 1px solid #64113F;
  margin-bottom: 20px;
}
.Apply .ApplicationForm form .Uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Apply .ApplicationForm form .Uploader .SubmitButton {
  align-self: center;
  margin: auto;
}
.Apply .ApplicationForm form .AppSectionContainer {
  background-color: #64113F;
  width: 100%;
  padding: 15px auto;
  margin-bottom: 20px;
}
.Apply .ApplicationForm form .AppSectionTitle {
  font: 400 28px "Kanit";
  color: white;
  margin-left: 15px;
  text-align: left;
}
.Apply .ApplicationForm form input {
  display: inline-block;
}
.Apply .ApplicationForm form label {
  display: inline-block;
}
.Apply .ApplicationForm form .Uploader {
  margin-bottom: 20px;
}
.Apply .ApplicationForm form .paragraph {
  font: 400 16px "Red Hat Display";
  color: #64113F;
  text-align: center;
}
@media (min-width: 600px) {
  .Apply .ApplicationForm form .paragraph {
    font-size: 20px;
  }
}

.Contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
}
.Contact .ContactInfo {
  font: 400 16px "Red Hat Display";
  color: #64113F;
  text-align: center;
  width: 90%;
  margin: 20px auto;
  text-align: center;
}
@media (min-width: 600px) {
  .Contact .ContactInfo {
    font-size: 20px;
  }
}
.Contact .ContactInfo a {
  color: #ECA72C;
}
@media (min-width: 600px) {
  .Contact .ContactInfo {
    width: 70%;
  }
}
.Contact .SocIcon {
  width: 100px;
  margin: auto;
  filter: brightness(0) saturate(100%) invert(10%) sepia(40%) saturate(5373%) hue-rotate(309deg) brightness(88%) contrast(96%);
  transition: filter 0.3s;
}
.Contact .SocIcon:hover {
  filter: brightness(0) saturate(100%) invert(81%) sepia(13%) saturate(4567%) hue-rotate(340deg) brightness(99%) contrast(87%);
}

label {
  color: #ECA72C;
  font: 700 16px "Red Hat Display";
}
@media (min-width: 600px) {
  label {
    font-size: 12px;
  }
}

.InputFull, .InputHalf, .InputFourth, .InputEighth, .TextArea {
  border: 1px solid #64113F;
  background-color: white;
  color: #64113F;
}

.InputFull {
  width: 90%;
}

.InputHalf {
  width: 45%;
}

.InputFourth {
  width: 22%;
}

.InputEighth {
  width: 11%;
}

.InputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-items: flex-start;
}

.TextArea {
  width: 100%;
  height: 100px;
}

.Success h2, .Success p {
  color: #64113F;
  text-align: center;
}
.Success h2 {
  font: 700 48px "Kanit";
  margin-top: 2rem;
}
.Success p {
  font: 400 20px "Red Hat Display";
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  white-space: pre-wrap;
}
@media (min-width: 1024px) {
  .App {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-items: center;
  }
}
.App .Content {
  overflow-x: hidden;
}
@media (min-width: 1024px) {
  .App .Content {
    width: 80vw;
    z-index: 0;
    position: relative;
    margin-left: 20vw;
  }
}

.TopImage {
  width: 100%;
  height: 26vh;
  object-fit: cover;
  z-index: 0;
  margin-bottom: -8vh;
}
@media (min-width: 600px) {
  .TopImage {
    margin-bottom: -50px;
    height: 40vh;
  }
}
@media (min-width: 1024px) {
  .TopImage {
    margin-bottom: -100px;
    height: 35vh;
  }
}

.TopCard {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 15vh;
  width: 80vw;
  background-color: #64113F;
  border-radius: 10px;
  z-index: 1;
  margin: auto;
}
@media (min-width: 600px) {
  .TopCard {
    height: 30vh;
    width: 70%;
  }
}
@media (min-width: 1024px) {
  .TopCard {
    height: 40vh;
    width: 70%;
  }
}
.TopCard h1 {
  font: 400 65px "Kanit";
  color: white;
  text-align: center;
}
@media (min-width: 600px) {
  .TopCard h1 {
    font-size: 85px;
  }
}
@media (min-width: 1024px) {
  .TopCard h1 {
    font-size: 105px;
  }
}

.SectionImage {
  width: 100%;
  height: 25vh;
}

.SectionTitle {
  font: 700 40px "Kanit";
  color: #64113F;
  text-align: center;
}
@media (min-width: 600px) {
  .SectionTitle {
    font-size: 46px;
  }
}
@media (min-width: 1024px) {
  .SectionTitle {
    font-size: 66px;
  }
}

.Uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #64113F;
  margin: auto;
  padding: 10px;
}

.NoDisplayMobile {
  display: none;
}

@media (min-width: 320px) and (min-width: 1024px) {
  .NoDisplayDesktop {
    display: none;
  }

  .NoDisplayMobile {
    display: block;
  }
}

