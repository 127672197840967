@use 'variables';
@use '../../node_modules/include-media/dist/include-media';

.About {
    .TopArea {
        margin-bottom: 10%;
    }
    .paragraph {
        @include variables.paragraph;
        width: 80%;
        margin: auto auto 10%;
        @include include-media.media(">desktop") {
            width: 70%;
        }
    }
    .BoardContainer {
        @include variables.flexColumn(space-evenly);
        gap: 20px;
        margin: 10% auto;
        @include include-media.media(">=desktop") {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
        .BoardCard {
            width: 80%;
            margin: auto;
            box-shadow: 0 5px 5px gray;
            border-radius: 10px;
            @include include-media.media(">=tablet") {
                width: 50%;
            }
            @include include-media.media(">=desktop") {
                width: 80%;
            }
            .BoardImage {
                width: 100%;
                height: auto;
                border-radius: 10px 10px 0 0;
            }
            .BoardInfo {
                background-color: variables.$baseColor;
                color: white;
                font: 400 18px "Kanit";
                text-align: center;
                min-height: 160px;
                border-radius: 0 0 10px 10px;
                .BoardName {
                    border-bottom: 1px solid white;
                    display: inline-block;
                    width: 90%;
                    margin-top: 2%;
                }
                .BoardTitle {
                    max-width: 90%;
                    margin: auto;
                }
            }
        }
    }
    h4 {
        text-align: center;
        margin: -30px auto 15%;
        font: 700 24px "Red Hat Display";
        color: variables.$baseColor;
    }
}