@use "variables";
@use '../../node_modules/include-media/dist/include-media';


.SubmitButton {
    background-color: transparent;
    color: white;
    font: 700 20px "Kanit";
    width: 30%;
    border-radius: 5px;
    border: 3px solid white;
    transition: background-color 0.5s, color 0.5s;
    &:hover {
        background-color: white;
        color: variables.$baseColor;
    }
    @include include-media.media(">=tablet") {
        max-width: 100px;
    }
}
.DonateButton {
    background-color: transparent;
    color: variables.$accentColor;
    font: 700 20px "Kanit";
    width: 30%;
    border-radius: 5px;
    border: 3px solid variables.$accentColor;
    transition: background-color 0.5s, color 0.5s;
    &:hover {
        background-color: variables.$accentColor;
        color: white;
    }
}
#mc_embed_signup {
    .clear {
        @include variables.centerContent;
        .MCButton {
            background-color: transparent;
            color: variables.$baseColor;
            font: 700 20px "Kanit";
            margin: auto;
            width: 35%;
            padding: auto;
            border-radius: 5px;
            border: 3px solid variables.$baseColor;
            transition: background-color 0.5s, color 0.5s;
            &:hover {
                background-color: variables.$baseColor;
                color: white;
            }
            @include include-media.media(">=tablet") {
                max-width: 100px;
            }
            @include include-media.media("<=tablet") {
                color: white;
                border-color: white;
                &:hover {
                    color: variables.$baseColor;
                    background-color: white;
                }
            }
            @include include-media.media(">=desktop") {
                width: 50%;
                min-width: 120px;
                max-width: none;
            }
        }
    }
}