@use 'variables';
@use '../../node_modules/include-media/dist/include-media';

.Apply {
    white-space: pre-wrap;
    margin-bottom: 80px;
    .ScholarshipInfo {
        @include variables.flexColumn(space-evenly);
        width: 85%;
        margin: auto;
        @include include-media.media(">=tablet") {
            width: 70%;
        }
        @include include-media.media(">=desktop") {
            width: 80%;
        }
        .paragraph {
            @include variables.paragraph;
            text-align: left;
            &:first-child {
                margin-top: 15%;
            }
            a {
                color: variables.$accentColor;
            }
        }
    }
    .ApplicationForm {
        @include variables.centerContent;
        flex-direction: column;
        width: 90%;
        margin: auto;
        @include include-media.media(">=tablet") {
            width: 70%;
        }
        @include include-media.media(">=desktop") {
            width: 80%;
        }
        .SubmitButton {
            margin: auto;
            text-align: center;
            color: variables.$baseColor;
            border-color: variables.$baseColor;
            &:hover {
                background-color: variables.$baseColor;
                color: white;
            }
        }
        form {
            @include variables.flexColumn(space-evenly);
            align-items: flex-start;
            gap: 7px;
            padding: 10px 15px;
            border: 1px solid variables.$baseColor;
            margin-bottom: 20px;
            // background-color: rgba(186, 17, 110, 0.1);
            .Uploader {
                @include variables.centerContent;
                flex-direction: column;
                .SubmitButton {
                    align-self: center;
                    margin: auto;
                }
            }
            .AppSectionContainer {
                background-color: variables.$baseColor;
                width: 100%;
                padding: 15px auto;
                margin-bottom: 20px;
            }
            .AppSectionTitle {
                font: 400 28px "Kanit";
                color: white;
                margin-left: 15px;
                text-align: left;
            }
            input {
                display: inline-block;
            }
            label {
                display: inline-block;
            }
            .Uploader {
                margin-bottom: 20px;
            }
            .paragraph {
                @include variables.paragraph;
            }
        }
    }
}