@use './variables';
@use './Buttons';
@use './Navbar';
@use './MobileBottom';
@use './Home';
@use './About';
@use './Apply';
@use './Contact';
@use './Inputs';
@use './Success';
@use '../../node_modules/include-media/dist/include-media';

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.App {
    margin: 0;
    padding: 0;
    width: 100vw;
    overflow-x: hidden;
    white-space: pre-wrap;
    @include include-media.media(">=desktop") {
        @include variables.flexRow(flex-start);
        align-items: center;
    }
    .Content {
        overflow-x: hidden;
        @include include-media.media(">=desktop") {
            width: 80vw;
            z-index: 0;
            position: relative;
            margin-left: 20vw;
        }
    }
}
.TopImage {
    width: 100%;
    height: 26vh;
    object-fit: cover;
    z-index: 0;
    margin-bottom: -8vh;
    @include include-media.media(">=tablet") {
        margin-bottom: -50px;
        height: 40vh;
    }
    @include include-media.media(">=desktop") {
        margin-bottom: -100px;
        height: 35vh;
    }
}
.TopCard {
    @include variables.centerContent;
    position: relative;
    height: 15vh;
    width: 80vw;
    background-color: variables.$baseColor;
    border-radius: 10px;
    z-index: 1;
    margin: auto;
    @include include-media.media(">=tablet") {
        height: 30vh;
        width: 70%;
    }
    @include include-media.media(">=desktop") {
        height: 40vh;
        width: 70%;
    }
    h1 {
        font: 400 65px "Kanit";
        color: white;
        text-align: center;
        @include include-media.media(">=tablet") {
            font-size: 85px;
        }
        @include include-media.media(">=desktop") {
            font-size: 105px;
        }
    }
}
.SectionImage {
    width: 100%;
    height: 25vh;
}
.SectionTitle {
    font: 700 40px "Kanit";
    color: variables.$baseColor;
    text-align: center;
    @include include-media.media(">=tablet") {
        font-size: 46px;
    }
    @include include-media.media(">=desktop") {
        font-size: 66px;
    }
}
.Uploader {
    @include variables.centerContent;
    border: 2px solid variables.$baseColor;
    margin: auto;
    padding: 10px;
}

// Media Queries
.NoDisplayMobile {
    display: none;
}
@include include-media.media('>=phone', '>=desktop') {
    .NoDisplayDesktop {
        display: none;
    }
    .NoDisplayMobile {
        display: block;
    }
}