@use './variables';
@use '../../node_modules/include-media/dist/include-media';

.Home {
    width: 100%;
    padding: 0;
    height: auto;
    overflow-x: hidden;
    .TopImage { 
        object-position: 0 25%;
    }
    .TopCard {
        background-image: url(../assets/img/SCLogo.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        @include include-media.media(">=tablet") {
            background-size: 70%;
        }
    }
    .paragraph {
        @include variables.paragraph;
        width: 80%;
        margin: 5% auto 0;
    }
    .SectionImage {
        object-fit: cover; 
        object-position: 0 25%;
        margin: 15% auto;
        @include include-media.media(">=tablet") {
            height: 50vh;
            width: 80%;
            margin: 10% auto 2% 8vw;
        }
    }
    .CardContainer {
        @include include-media.media(">=desktop") {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            padding-bottom: 0;
            margin-bottom: 0;
            margin: 0 0 0 -1%;
        }
    }
    .AlumniCard {
        @include variables.flexColumn(space-between);
        align-items: center;
        width: 90%;
        // max-width: 500px;
        height: 90vh;
        margin: 5vh auto;
        padding: 0;
        box-shadow: 0 10px 10px variables.$shadow;
        border-radius: 10px;
        @include include-media.media(">=tablet") {
            width: 60vw;
            height: auto;
        }
        @include include-media.media(">=desktop") {
            width: 25vw;
            min-width: 250px;
            height: auto;
        }
        .AlumniImage {
            margin: 0;
            padding: 0;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
            // object-position: 0 50px;
            background-color: variables.$baseColor;
        }
        .AlumniName {
            display: block;
            width: 100%;
            text-align: center;
            font: 400 28px "Kanit";
            color: variables.$baseColor;
            border-bottom: 1px solid variables.$baseColor;
        }
        .QuoteContainer {
            text-align: center;
            height: 40%;
            @include include-media.media(">=tablet") {
                height: 30%;
            }
            @include include-media.media(">=desktop") {
                height: 50%;
            }
            .AlumniQuote {
                font: 400 14px "Red Hat Display";
                color: variables.$baseColor;
                width: 90%;
                margin: 1% auto;
                @include variables.centerContent;
                // margin: 2% auto 5%;
                @include include-media.media(">=tablet") {
                    font-size: 16px;
                }
                @include include-media.media(">=desktop") {
                    font-size: 12px;
                }
                @include include-media.media(">=large") {
                    font-size: 16px;
                }
            }
        }
        .AlumniYear {
            display: block;
            position: relative;
            bottom: 0;
            width: 100%;
            height: auto;
            text-align: center;
            margin: 2% auto 0;
            background-color: variables.$baseColor;
            color: variables.$accentColor;
            font: 700 40px "Kanit";
            border-radius: 0 0 10px 10px;
            @include include-media.media(">=tablet") {
                font-size: 50px;
            }
            @include include-media.media(">=desktop") {
                font-size: 35px;
            }
        }
        // .QuoteBox {
        //     @include variables.centerContent;
        //     width: 100%;
        //     height: 8vh;
        //     margin: 0 auto;
        //     background-color: variables.$baseColor;
        //     .QuoteIcon {
        //         filter: invert(67%) sepia(85%) saturate(463%) hue-rotate(344deg) brightness(95%) contrast(94%);
        //         width: 30%;
        //         max-width: 100px;
        //         height: auto;
        //         @include include-media.media(">=tablet") {
        //             max-width: 70px;
        //         }
        //         @include include-media.media(">=desktop") {
        //             width: 60px;
        //         }
        //     }
        // }
        // .AlumniFlair {
        //     margin: auto;
        //     @include variables.centerContent;
        //     flex-direction: column;
        //     .AlumniQuote {
        //         color: variables.$accentColor;
        //         font: 400 24px "Red Hat Display";
        //         @include include-media.media(">=desktop") {
        //             font-size: 20px;
        //         }
        //     }
        //     .AlumniImage {
        //         width: 80%;
        //         height: auto;
        //         margin: 3vh auto;
        //         border-radius: 100%;
        //         box-shadow: 0 8px 8px variables.$shadow;
        //         @include include-media.media(">=tablet") {
        //             width: 60%;
        //         }
        //         @include include-media.media(">=desktop") {
        //             width: 80%;
        //         }
        //     }
        // }
        // .AlumniInfo {
        //     @include variables.centerContent;
        //     justify-content: center;
        //     width: 100%;
        //     height: 10vh;
        //     display: block;
        //     background-color: variables.$baseColor;
        //     @include include-media.media(">=tablet") {
        //         @include variables.flexRow(center);
        //     }
        //     h3 {
        //         color: white;
        //         text-align: center;
        //         font: 700 30px "Kanit";
        //         margin: -10px auto 0;
        //         @include include-media.media(">=tablet") {
        //             font-size: 32px;
        //             margin-top: -5px;
        //         }
        //         @include include-media.media(">=desktop") {
        //             font-size: 20px;
        //             margin-top: -5px;
        //         }
        //     }
        //}
    }
}